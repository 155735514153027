import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useSessionStorage = <T>(
    key: string,
    initialValue: T,
    debounceDuration = 30000,
): [T, Dispatch<SetStateAction<T>>] => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: T | ((prevValue: T) => T)) => {
        try {
            const newValue = value instanceof Function ? value(storedValue) : value;
            setStoredValue(newValue);
            window.sessionStorage.setItem(key, JSON.stringify(newValue));
        } catch (error) {
            console.error('Error accessing sessionStorage:', error);
        }
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            window.sessionStorage.removeItem(key);
        }, debounceDuration);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [key, debounceDuration]);

    return [storedValue, setValue];
};
