import { FC, ReactElement, ReactNode } from 'react';

import { GrandPrix, GrandPrixSchedule as Schedule } from '@common/clients/api';
import { Winner } from '@web/atoms/racing/types';

import { GrandPrixSchedule } from '../GrandPrixSchedule';
import { TagIntroBlock, TagIntroBlockType } from '../TagIntroBlock';

import styles from './GrandPrixWidget.module.scss';

export interface TagDataType {
    [key: string]: string | ReactNode | Schedule[] | Winner[];
}

export interface Props {
    title: string | ReactElement;
    tag?: string;
    backgroundImage?: string;
    data?: GrandPrix;
    linkTagSlug?: string;
    hasVerticalSchedule?: boolean;
}

export const GrandPrixWidget: FC<Props> = ({
    title,
    tag,
    backgroundImage,
    data,
    linkTagSlug,
    hasVerticalSchedule,
}) => (
    <div className={styles.GrandPrixWidget}>
        <TagIntroBlock {...{ tag, title, backgroundImage }} type={TagIntroBlockType.GRAND_PRIX} isMiniView />
        <div className={styles['bottom-wrapper']}>
            <GrandPrixSchedule
                isPastRace={false}
                schedule={data?.schedule}
                grandPrixSlug={linkTagSlug}
                isMiniView
                hasVerticalSchedule={hasVerticalSchedule}
            />
            {typeof data?.scheduleTimezone === 'string' && (
                <p className={styles.disclaimer}>{data?.scheduleTimezone}</p>
            )}
        </div>
    </div>
);
