import { News, NewsService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultArrayCatch } from '@common/defaults';

export const fetchMostReadNews = async (
    props: ContextData,
    isClientSide: boolean = true,
): Promise<News[]> => {
    const request = new ApiBaseRequest(props, isClientSide);
    const newsService = new NewsService(request);
    const response = await newsService
        .getMostReadNewsByDomainId({
            contextId: props.context.domain.domainID,
            isMedia: false,
            cache: true,
        })
        .catch(defaultArrayCatch);
    return response;
};
