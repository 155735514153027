import { GrandPrix, News, PlatformID } from '@common/clients/api';
import { SimpleCommentProps } from '@web/molecules';
import { GrandPrixWidgetProps } from '@web/molecules/GrandPrixWidget';
import { Props as NewsBlockItemProps } from '@web/molecules/NewsBlockItem/NewsBlockItem';
import { SidebarSequence } from '@web/organisms/Sidebar/utils/Sequence';

export type SidebarSequenceWidgets = Record<SidebarSequence, Array<WidgetType>>;
export type PlatformIncludeWidgets = Record<PlatformID, Array<WidgetType>>;

/**
 * Type representing the available widget types.
 */
export enum WidgetType {
    MOST_COMMENTED = 'most-commented',
    MOST_LIKED_COMMENTS = 'most-liked-comments',
    MOST_READ = 'most-read',
    MOST_VIEWED = 'most-viewed',
    TRANSFER_RUMOUR = 'transfer-rumour',
    UPCOMING_GP = 'upcoming-gp',
}

/**
 * Type representing the raw data fetched from the server for each widget.
 */
export type SidebarServerData = {
    [WidgetType.MOST_LIKED_COMMENTS]?: News[];
    [WidgetType.MOST_READ]?: News[];
    [WidgetType.MOST_COMMENTED]?: News[];
    [WidgetType.TRANSFER_RUMOUR]?: News[];
    [WidgetType.MOST_VIEWED]?: News[];
    [WidgetType.UPCOMING_GP]?: GrandPrix;
};

export interface WidgetData {
    [key: string]: SidebarServerData[keyof SidebarServerData];
}

/**
 * Type representing the transformed props passed to each widget.
 */
export type SidebarWidgetProps = {
    [WidgetType.MOST_LIKED_COMMENTS]?: SimpleCommentProps[];
    [WidgetType.MOST_READ]?: NewsBlockItemProps[];
    [WidgetType.MOST_COMMENTED]?: NewsBlockItemProps[];
    [WidgetType.TRANSFER_RUMOUR]?: NewsBlockItemProps[];
    [WidgetType.MOST_VIEWED]?: NewsBlockItemProps[];
    [WidgetType.UPCOMING_GP]?: GrandPrixWidgetProps;
};

export interface WidgetProps {
    [key: string]: SidebarWidgetProps[keyof SidebarWidgetProps];
}

export interface SidebarData extends SidebarWidgetProps {
    sequence: SidebarSequence;
}
