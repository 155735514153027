import { FC } from 'react';

import { UserRole } from '@common/clients/api';
import { Icon } from '@common/elements/Icon';

const getAuthorIcon = (role: UserRole): JSX.Element | null => {
    switch (role) {
        case UserRole._2:
            return <Icon.moderator />;
        case UserRole._3:
            return <Icon.editor />;
        case UserRole._4:
            return <Icon.admin />;
        default:
            return null;
    }
};

interface Props {
    role: UserRole;
}

export const UserRoleIcon: FC<Props> = ({ role }) => getAuthorIcon(role);
