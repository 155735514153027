import { FC } from 'react';

interface Props {
    width?: number;
    height?: number;
}

export const ViewsIcon: FC<Props> = ({ height, width }) => (
    <svg
        width={width ? width : '24px'}
        height={height ? height : '24px'}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.977,13.783 L19.977,4.783 C19.8745557,4.32561364 19.4687185,4.00036234 19,4 L16,4 L16,6 L18.198,6 L19.159,10.326 C18.6317943,10.1127167 18.0687103,10.0020685 17.5,10 C15.547,10 13.897,11.258 13.276,13 L10.723,13 C10.102,11.258 8.452,10 6.499,10 C5.912,10 5.354,10.121 4.84,10.326 L5.802,6 L8,6 L8,4 L5,4 C4.5313923,4 4.12568786,4.32555846 4.024,4.783 L2.024,13.783 L2.071,13.794 C2.02899968,14.0271275 2.00526092,14.2631778 2,14.5 C2,16.981 4.019,19 6.5,19 C8.81,19 10.697,17.244 10.949,15 L13.051,15 C13.303,17.244 15.19,19 17.5,19 C19.981,19 22,16.981 22,14.5 C22,14.258 21.966,14.025 21.929,13.794 L21.977,13.783 L21.977,13.783 Z M6.5,17 C5.122,17 4,15.878 4,14.5 C4,13.122 5.122,12 6.5,12 C7.878,12 9,13.122 9,14.5 C9,15.878 7.878,17 6.5,17 Z M17.5,17 C16.121,17 15,15.878 15,14.5 C15,13.122 16.121,12 17.5,12 C18.879,12 20,13.122 20,14.5 C20,15.878 18.879,17 17.5,17 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
        ></path>
    </svg>
);
