import { NewsCategoryService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';

export const fetchNewsCategory = async <T extends ContextData>(
    props: T,
    slug: string,
    isClientSide: boolean,
) => {
    const request = new ApiBaseRequest(props, isClientSide);
    const newsCategoryService = new NewsCategoryService(request);
    const response = await newsCategoryService
        .listNewsCategoryByDomainId({ contextId: props.context.domain.domainID, nameSlug: slug })
        .catch(defaultCatch);

    return response?.[0] || undefined;
};
