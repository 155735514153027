import { FC } from 'react';

import { UserRole } from '@common/clients/api';
import { UserRoleIcon } from '@web/atoms/UserRoleIcon';

import { CommentHeader } from './CommentHeader';

import styles from '../SimpleComment.module.scss';

export interface Props {
    classes?: string;
    userLikeCount?: number;
    reactionTxt?: string;
    role?: UserRole;
    userName: string;
}

export const CommentCard: FC<Props> = ({ classes, userLikeCount, reactionTxt, role, userName }) => (
    <div className={`${classes} ${styles['reply-card']}`}>
        <CommentHeader
            userName={userName}
            userLikeCount={Number(userLikeCount)}
            AuthorIcon={<UserRoleIcon role={role as UserRole} />}
        />
        <p className={styles['reply-content']}>{reactionTxt}</p>
    </div>
);
