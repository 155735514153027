import { FC } from 'react';
import { ImageProps } from 'next/image';

import { ThumbnailWidth } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';

import { WebpImage } from '../WebpImage';

import styles from './Avatar.module.scss';

export interface Props extends Partial<ImageProps> {
    src?: string;
    userName?: string;
    isRounded?: boolean;
    isSmall?: boolean;
}

export const Avatar: FC<Props> = ({ userName, className, isRounded, isSmall, src }) => {
    const {
        context: {
            platform: { assetsBaseHref },
        },
    } = useContextData();

    const classes = [styles.Avatar];
    if (isRounded) classes.push(styles['is-rounded']);
    if (isSmall) classes.push(styles['is-small']);
    if (className) classes.push(className);

    return (
        <WebpImage
            className={classes.join(' ')}
            defaultSize={ThumbnailWidth.WIDTH_110}
            src={src}
            alt={userName || ''}
            ratio={1}
            fallbackSrc={`${assetsBaseHref}images/common/avatar_default.png`}
        />
    );
};
