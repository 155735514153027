/**
 * Map fetched data to the format that is used by the component
 *
 * @param {string} itemCountKey - key of the count property in the fetched data
 * @param {boolean} addIndex - add index to the listed items to be shown in a side widget
 *
 * @example
 * mapFetchedData('commentCount')(commentItems)
 * // Returns [{ src: 'https://..*.jpeg', title: 'An article title', count: 256, url:'..*.html' }]
 */
export const mapFetchedData = (itemCountKey?: string, addIndex?: boolean) => {
    return (dataItems: any[]) => {
        const mappedItems = dataItems.map((currentItem) => {
            const count = itemCountKey && itemCountKey in currentItem ? currentItem[itemCountKey] || 0 : 0;

            return {
                src: currentItem.image || '',
                title: currentItem.newsTitle || '',
                count,
                url: currentItem.path,
            };
        });

        return !addIndex ? mappedItems : mappedItems.map((item, i) => ({ ...item, index: i + 1 }));
    };
};
