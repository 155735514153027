import { News, NewsService, NewsStatus } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';
import { fetchNewsCategory } from '@web/handlers/fetchNewsCategory';

export const fetchTransferNews = async (
    props: ContextData,
    isClientSide: boolean = true,
): Promise<News[]> => {
    const newsCategory = await fetchNewsCategory(props, 'transfergeruchten', isClientSide);
    const newsCatId = newsCategory?.newsCatID;

    if (typeof newsCatId !== 'undefined') {
        const request = new ApiBaseRequest(props, true);
        const newsService = new NewsService(request);
        const response = await newsService
            .listNewsByDomainId({
                contextId: props.context.domain.domainID,
                isMedia: false,
                checkNextPage: true,
                cache: true,
                newsCatId: newsCatId,
                page: 1,
                perPage: 3,
                newsStatus: NewsStatus.PUBLISHED,
            })
            .catch(defaultCatch);
        return response?.data ?? [];
    }
    return [];
};
