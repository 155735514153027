import { Translate } from 'next-translate';

import { PlatformID } from '@common/clients/api';
import { ChosenIcon } from '@common/elements/ChosenIcon';
import { Option } from '@common/elements/Icon/Icon';
import { ViewsIcon } from '@web/organisms/SideWidgets/icons/Icon_';
import { HighlightedCategory } from '@web/organisms/SideWidgets/types';

export const switchNewsBlockTitle = (category: HighlightedCategory, __translate: Translate) => {
    switch (category) {
        case 'commented':
            return __translate('most-comments.title');
        case 'liked':
            return __translate('popular-comments.title');
        case 'watched':
            return __translate('most-watched.title');
        case 'read':
            return __translate('most-read.title');
        case 'rumour':
            return __translate('transfers.title');
        default:
            return '';
    }
};

export const switchHighlightedMeta = (category: HighlightedCategory, __translate: Translate) => {
    switch (category) {
        case 'commented':
            return __translate('most-comments.count');
        case 'liked':
            return __translate('popular-comments.count');
        case 'watched':
            return __translate('most-watched.count');
        case 'read':
            return __translate('most-read.count');
        default:
            return '';
    }
};

export const switchMetaIcon = (category: HighlightedCategory) => {
    switch (category) {
        case 'commented':
            return <ChosenIcon icon={Option.comment} />;
        case 'liked':
            return <ChosenIcon icon={Option.like} />;
        case 'watched':
            return <ChosenIcon icon={Option.show} />;
        case 'read':
            return <ViewsIcon />;
        default:
            return null;
    }
};

export const switchCustomPlatformBranding = (platform: PlatformID) => {
    switch (platform) {
        case PlatformID.GP:
            return 'GPBlog';
        case PlatformID.BR:
            return 'Bright';
        default:
            return platform.toUpperCase();
    }
};

export const switchPopularWidgetPlatformTabs = (platform: PlatformID) => {
    switch (platform) {
        case PlatformID.BR:
            return ['read'];
        default:
            return ['read', 'commented'];
    }
};
