import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import styles from './WidgetLoader.module.scss';

export enum Status {
    LOADING,
    EMPTY,
}

interface Props {
    status: Status;
}

export const WidgetLoader: FC<Props> = ({ status }) => {
    const __meta = useTranslation('meta').t;
    return (
        <aside className={styles.WidgetLoader}>
            {status === Status.LOADING ? (
                <div>{__meta('data.loading')}</div>
            ) : status === Status.EMPTY ? (
                <div>{__meta('data.empty')}</div>
            ) : null}
        </aside>
    );
};
