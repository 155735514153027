import { Comment, CommentService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultArrayCatch } from '@common/defaults';

export const fetchMostLikedComments = async (
    props: ContextData,
    isClientSide: boolean = true,
): Promise<Comment[]> => {
    const request = new ApiBaseRequest(props, isClientSide);
    const commentService = new CommentService(request);
    const response = await commentService
        .getMostLikesCommentByDomainId({
            contextId: props.context.domain.domainID,
            cache: true,
        })
        .catch(defaultArrayCatch);
    return response;
};
