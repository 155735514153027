import { GrandPrix, GrandPrixService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';

const MAX_DAYS_UNTIL_NEXT_GP = 14;

export const fetchUpcomingGrandPrix = async (
    props: ContextData,
    isClientSide: boolean = true,
): Promise<GrandPrix | undefined> => {
    const request = new ApiBaseRequest(props, isClientSide);
    const gpService = new GrandPrixService(request);
    const response = await gpService
        .upcomingGrandPrixByDomainId({
            contextId: props.context.domain.domainID,
            days: MAX_DAYS_UNTIL_NEXT_GP,
        })
        .catch(defaultCatch);

    return response;
};
