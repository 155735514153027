import { FC, memo, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Header as WidgetHeader } from '@web/atoms/Header';
import { Props as NewsBlockItemProps } from '@web/molecules/NewsBlockItem/NewsBlockItem';
import { Widget, WidgetTitle } from '@web/molecules/Widget';
import styles from '@web/molecules/Widget/Widget.module.scss';
import { Tabs as WidgetTabs } from '@web/molecules/WidgetTabs';
import { HighlightedCategory } from '@web/organisms/SideWidgets/types';
import {
    switchCustomPlatformBranding,
    switchMetaIcon,
    switchNewsBlockTitle,
    switchPopularWidgetPlatformTabs,
} from '@web/organisms/SideWidgets/utils';

import { MostCommented, Widget as MostCommentedStatic } from './MostCommented';
import { MostRead, Widget as MostReadStatic } from './MostRead';

interface Props {
    isServerSideRendered?: boolean;
    staticData?: {
        read: NewsBlockItemProps[];
        commented: NewsBlockItemProps[];
    };
}

const DEFAULT_PLATFORM = PlatformID.VP;

const TabbedWidget: FC<Props> = ({ isServerSideRendered, staticData }) => {
    const __sidebar = useTranslation('sidebar').t;
    const { context } = useContextData();
    const { domain } = context;
    const platform = domain?.platform || DEFAULT_PLATFORM;

    const { platformName, platformTabs } = useMemo(
        () => ({
            platformName: switchCustomPlatformBranding(platform),
            platformTabs: switchPopularWidgetPlatformTabs(platform),
        }),
        [platform],
    );

    const [highlighted, setHighlighted] = useState(platformTabs[0]);
    const highlightedTabData = useMemo(
        () => ({
            metaIcon: switchMetaIcon(highlighted as HighlightedCategory),
            heading: __sidebar('popular.title', { platform: platformName }),
            tabLabelTranslations: platformTabs.map((key) =>
                switchNewsBlockTitle(key as HighlightedCategory, __sidebar),
            ),
            isActive: {
                read: highlighted.includes('read'),
                commented: highlighted.includes('commented'),
            },
        }),
        [highlighted, __sidebar, platformName, platformTabs],
    );

    const onTabClick = (clickedTab: string) => () => {
        if (highlighted !== clickedTab) {
            setHighlighted(clickedTab as HighlightedCategory);
        }
        return clickedTab;
    };

    const { isActive } = highlightedTabData;

    return (
        <Widget>
            <WidgetHeader>
                <WidgetTitle
                    heading={highlightedTabData.heading}
                    classes={platformTabs.length > 1 ? styles['border-override'] : styles['border-third']}
                />
                {platformTabs.length > 1 ? (
                    <>
                        <hr />
                        <WidgetTabs
                            tabLabels={highlightedTabData.tabLabelTranslations}
                            tabDataKeys={platformTabs}
                            onTabClick={onTabClick}
                            highlighted={highlighted}
                        />
                    </>
                ) : null}
            </WidgetHeader>
            <hr />
            {isActive.read ? (
                !staticData?.read ? (
                    !isServerSideRendered ? (
                        <MostRead />
                    ) : null
                ) : (
                    <MostReadStatic staticData={staticData?.read || []} />
                )
            ) : null}
            {isActive.commented ? (
                !staticData?.commented ? (
                    !isServerSideRendered ? (
                        <MostCommented />
                    ) : null
                ) : (
                    <MostCommentedStatic staticData={staticData?.commented || []} />
                )
            ) : null}
        </Widget>
    );
};

export const NewsPopular = memo(TabbedWidget);
