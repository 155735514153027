import { ReactNode, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { ContextData } from '@common/defaults';
import { Duration } from '@common/types/Duration';
import { useContextData } from '@common/useContextData';
import { useSessionStorage } from '@web/hooks/useSessionStorage/useSessionStorage';
import { Status, WidgetLoader } from '@web/molecules/Widget/atoms';
import { WidgetType } from '@web/organisms/Sidebar/types';

const LOCAL_CACHE_TIME = 5 * Duration.minute;

export type FetcherType<R> = (args: ContextData, isClientSide?: boolean) => Promise<R>;
export type MapperType<T, U> = (data: T) => U;

export type FetcherProps<R, U> = {
    fetcher: FetcherType<R>;
    mapper: MapperType<R, U>;
    storageKey: WidgetType;
};

// TODO: PB-4943 - Update ticket created to improve reusability
export interface DataFetcherProps<R, U> extends FetcherProps<R, U> {
    children: (props: { dataList: U }) => ReactNode;
}

export const DataFetcher = <R, U>({ children, fetcher, mapper, storageKey }: DataFetcherProps<R, U>) => {
    const contextData = useContextData();
    const {
        context: {
            domain: { locale, platform },
        },
    } = contextData;

    const [fetchedData, setFetchedData] = useSessionStorage<U | null>(
        `${storageKey}_${platform}_${locale}`,
        null,
        LOCAL_CACHE_TIME,
    );
    const [hasFetched, setHasFetched] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            if (fetchedData === null) {
                if (!hasFetched) {
                    const response = (await fetcher(contextData)) as R;
                    const mappedData = mapper(response);
                    setFetchedData(mappedData);
                    setHasFetched(true);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [hasFetched, fetchedData, setFetchedData, contextData, fetcher, mapper]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const dataList = useMemo(() => fetchedData, [fetchedData]) as U;

    return (
        <Suspense fallback={<WidgetLoader status={Status.LOADING} />}>
            {!isEmpty(dataList) ? children({ dataList }) : <WidgetLoader status={Status.EMPTY} />}
        </Suspense>
    );
};
