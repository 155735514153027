import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { DataFetcher, FetcherProps, SimpleWidget } from 'web/organisms/SimpleWidget';

import { News } from '@common/clients/api';
import { Icon } from '@common/elements/Icon';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { fetchTransferNews } from '@web/handlers/fetchTransferRumour';
import { NewsBlockItem, Props as NewsBlockItemProps } from '@web/molecules/NewsBlockItem/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';

import { mapFetchedData, switchNewsBlockTitle } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
    staticData?: NewsBlockItemProps[];
}

export const Widget: FC<Props> = ({ dataList, staticData }) => {
    const __sidebar = useTranslation('sidebar').t;

    return (
        <SimpleWidget
            metaKey={'commented'}
            ComponentItem={NewsBlockItem}
            dataList={dataList}
            staticData={staticData}
            title={switchNewsBlockTitle('rumour', __sidebar)}
            titleClasses={'border-fourth'}
            renderHeader={() => (
                <InternalLink route={Route.TransferRumors}>
                    {__sidebar('rumour.show-more')}
                    <Icon.right />
                </InternalLink>
            )}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchTransferNews,
    mapper: mapFetchedData('commentCount', false),
    storageKey: WidgetType.TRANSFER_RUMOUR,
};

export const TransferRumour = () => (
    <DataFetcher {...fetcherProps}>{({ dataList }) => <Widget dataList={dataList} />}</DataFetcher>
);
