import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { DataFetcher, FetcherProps, SimpleWidget } from 'web/organisms/SimpleWidget';

import { News } from '@common/clients/api';
import { fetchMostViewedNewsMedia } from '@web/handlers/fetchMostViewedNewsMedia';
import { NewsBlockItem, Props as NewsBlockItemProps } from '@web/molecules/NewsBlockItem/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';

import { mapFetchedData, switchNewsBlockTitle } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
    staticData?: NewsBlockItemProps[];
}

export const Widget: FC<Props> = ({ dataList, staticData }) => {
    const __sidebar = useTranslation('sidebar').t;

    return (
        <SimpleWidget
            title={switchNewsBlockTitle('watched', __sidebar)}
            metaKey={'watched'}
            ComponentItem={NewsBlockItem as FC<NewsBlockItemProps>}
            dataList={dataList}
            staticData={staticData}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchMostViewedNewsMedia,
    mapper: mapFetchedData('visitCount', true),
    storageKey: WidgetType.MOST_VIEWED,
};

export const MostViewed = () => (
    <DataFetcher {...fetcherProps}>{({ dataList }) => <Widget dataList={dataList} />}</DataFetcher>
);
